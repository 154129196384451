import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import config from '../../data/SiteConfig'
import { graphql } from 'gatsby'
import { HTML } from '../components/index'

const WorkWithMePage = ({
  data: {
    pagesYaml: { cover, intro },
  },
}) => (
  <Layout heroImage={cover}>
    <Helmet title={`Work With Me | ${config.siteTitle}`} />
    <HTML html={intro} />
  </Layout>
)

export const workWithMeQuery = graphql`
  query WorkWithMeQuery {
    pagesYaml(id: { eq: "workWithMe" }) {
      intro
      id
      cover
    }
  }
`

export default WorkWithMePage
